import logo from './smc-logo-typo-color-big.png';
import './App.css';
import React, {Component} from 'react';
import {Impressum, Datenschutz, Home} from "./";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

class App extends Component {
  
  render() {
    // return reference to whole notebook: 
    return (<div>
        <Router>
        <main className="app">
          <div class="header-logo"><img src={logo}/></div>
          <Switch>
            <Route path="/" exact component={() => <Home />} />
            <Route path="/impressum" exact component={() => <Impressum />} />
            <Route path="/datenschutz" exact component={() => <Datenschutz />} />
          </Switch>
          <footer>
            <div>©Science Media Center Germany gGmbH {new Date().getFullYear()}</div>
            <div><a href="/impressum">Impressum</a> | <a href="/datenschutz">Datenschutz</a></div>
          </footer>
        </main>
        </Router>
      </div>
      
    );
    
  }
}

export default App;