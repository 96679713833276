import logo from './logo.svg';
import './App.css';
import React, {Component} from 'react';
import {Runtime, Inspector} from "@observablehq/runtime";
import define from "@berhardki/die-suche-nach-den-dunkelflauten";

class Home extends Component {
  mainRef = React.createRef();
  
  componentDidMount() {
    const runtime = new Runtime();
    // create reference to the whole notebook:
    runtime.module(define, Inspector.into(this.mainRef.current));
  }

  render() {
    // return reference to whole notebook: 
    return (
        <div ref={this.mainRef} />
    );
  }
}

export default Home;